import styled from 'styled-components'
import { Text, Box } from 'grommet'
import { colors, responsive } from '../../../theme';

export const ItemWrapper = styled(Box)`
  margin: -20px 0 30px;
`;

export const Title = styled(Text)`
  font-size: 5rem;
  color: ${colors.black};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-weight: bold;
  font-family: 'Monda', sans-serif;
  
  ${responsive.xsAndLess} {
    font-size: 3rem;
  }
`;

export const SubTitle = styled(Text)`
  font-size: 1rem;
  color: ${colors.black};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-family: 'Roboto Slab', 'Cinzel', serif;
  
  ${responsive.xsAndLess} {
    font-size: .7rem;
  }

  em {
    font-weight: bold;
  }

  strong {
    
  }
`;
