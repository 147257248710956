import styled from "styled-components"
import { Box } from "grommet"
import { colors, responsive } from "../../theme"

export const BottomGrid = styled.div`
  grid-area: footer;
`

export const LeftGrid = styled(Box)`
  grid-area: left;
  justify-content: center;
  align-items: center;
  display: flex;
  
  ${responsive.smAndLess} {
    visibility: hidden;
  }
`

export const CenterGrid = styled(Box)`
  grid-area: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: auto;
    
  ${responsive.md} {
    padding: 0 120px;
  }

  ${responsive.lg} {
    padding: 0 220px;
  }

  ${responsive.xsAndLess} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

export const RightGrid = styled(Box)`
  grid-area: right;
  justify-content: center;
  align-items: center;
  display: flex;
  
  ${responsive.smAndLess} {
    visibility: hidden;
  }
`

export const Container = styled(Box)`
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    
    max-height: max-content;
    
    padding-top: 90px;
    min-height: 100%;
  
    grid-template-areas:
      "left center right"
      "left center right"
      "left center right"
      "left center right"
      "left center right"
      "left center right"
      "left center right"
      "footer footer footer"
     ;
    
  ${responsive.xsAndLess} {
    display: grid; 
    grid-template-columns: 0.0001fr 1fr 0.0001fr; 
    grid-template-rows: 1fr 0.1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    padding-top: 85px;

    grid-template-areas: 
      "left center right"
      "footer footer footer"
    ;
  }
`

export const VerticalText = styled.p`
  font-size: .7rem;
  letter-spacing: 3px;
  text-orientation: mixed;
  transform: rotate(-90deg);
  color: ${colors.black};
  font-family: 'Monda', sans-serif;
`;
