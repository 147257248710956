import React from 'react'
import { Box, Column } from 'gestalt'
import PropTypes from 'prop-types'
import { Box as GBox, TextInput, TextArea, Button } from 'grommet'
import { Phone, MailOption, Twitter, Linkedin } from 'grommet-icons'

import { ItemWrapper, Title, SubTitle } from './styles'
import { colors } from '../../../theme';
import FragmentWrapper from "../fragment-wrapper"

const textStyle = { fontFamily: "'Open Sans', sans-serif", fontWeight: '100', fontSize: '1rem', color: colors.black }

const IconList = ({ renderIcon, text, linkType }) => (
  <ItemWrapper direction="row" align="center">
    <Box padding={2}>
      {renderIcon()}
    </Box>
    <Box padding={2} alignSelf="center">
      {linkType === 'tel' && (<a target="_blank" rel="noopener noreferrer" href={`tel:${text}`} style={textStyle}>{text}</a>)}
      {linkType === 'mailto' && (<a target="_blank" rel="noopener noreferrer" href={`mailto:${text}`} style={textStyle}>{text}</a>)}
      {linkType === 'other' && (<a target="_blank" rel="noopener noreferrer" href={'//'+text} style={textStyle}>{text}</a>)}
    </Box>
  </ItemWrapper>
)

IconList.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
}

IconList.defaultProps = {
  linkType: 'other',
}

const ContactFragment = (props) => {
  const inputStyle = { margin: "-20px 0px 35px", fontFamily: "'Open Sans', sans-serif" }
  const inputStyleArea = { margin: "-20px 0px 35px", height: 140, fontFamily: "'Open Sans', sans-serif" }

  return (
    <FragmentWrapper
      rightContent={() => <div />}
    >
      <GBox justify="center" pad={{ horizontal: "large" }}>
        <Box>
          <Box paddingY={6}>
            <Title>Get in touch!</Title>
          </Box>
          <Box paddingY={2} marginBottom={12}>
            <SubTitle>Do you want to say hi to me, need advice regarding your project and you need my help? feel free to contact me.</SubTitle>
            <br/>
          </Box>
        </Box>
        <Box
          display="flex"
          color="transparent"
          flex
          direction="row"
          wrap
        >
          <Column span={12} mdSpan={6}>
            <Box color="transparent">
              <IconList renderIcon={() => <MailOption color={colors.black} />} linkType="mailto" text="***.***@*****.***"/>
              <IconList renderIcon={() => <Phone color={colors.black} />} linkType="tel" text="+47 *** ** ***"/>
              <IconList renderIcon={() => <Twitter color={colors.black} />} text="twitter.com/rex_raph"/>
              <IconList renderIcon={() => <Linkedin color={colors.black} />} text="linkedin.com/in/rexraphael"/>
            </Box>
          </Column>
          <Column span={12} mdSpan={6}>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              action="https://formspree.io/rex.raphael@outlook.com"
            >
              <GBox>
                <TextInput type="text" name="name" id="full-name" placeholder="Name" style={inputStyle} />
                <TextInput type="email" name="_replyto" id="email-address" placeholder="Email" style={inputStyle} />
                <TextArea
                  style={inputStyleArea}
                  placeholder="Message"
                  resize={false}
                  name="message"
                  id="message"
                />
                <GBox flex alignSelf="auto">
                  <GBox width="50%" alignSelf="end">
                    <Button
                      fill={false}
                      primary
                      label="SEND MESSAGE"
                      style={{ fontFamily: "'Open Sans', sans-serif" }}
                      type="submit"
                    />
                  </GBox>
                </GBox>
              </GBox>
              </form>
          </Column>
        </Box>
      </GBox>
    </FragmentWrapper>
  )
}

export default ContactFragment
