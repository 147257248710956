import styled from 'styled-components'
import is from 'styled-is';
import { colors, responsive } from '../../theme';

export const Wrapper = styled.footer`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const CopyRight = styled.p`
  font-size: .7rem;
  color: ${colors.black};
  font-family: 'Josefin Sans', sans-serif;
  text-align: right;
  
  ${responsive.xsAndLess} {
    text-align: center;
  }

  ${is('darkMode')`
    color: white;
  `};
`;

export const BackText = styled.p`
  font-size: .7rem;
  color: ${colors.black};
  font-family: 'Josefin Sans', sans-serif;
  text-align: left;
    
  ${is('darkMode')`
    color: white;
  `};
`;

export const Hr = styled.hr`
  font-size: .7rem;
  color: ${colors.black};
  font-family: 'Josefin Sans', sans-serif;
  text-align: right;
`;

export const LinkB = styled.div`
  cursor: pointer;
`;

export const HortLine = styled.div`
  min-width: 100px;
  width: auto;
  max-width: 150px;
  align-self: center;
  
  height: 1px;
  margin-right: 9px;
  
  display: block;
  background-image: -webkit-linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:    -moz-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:     -ms-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:      -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
    
  ${is('darkMode')`
      background-image: -webkit-linear-gradient( rgba(225,225,225,0), rgba(225,225,225,0.75), rgba(225,225,225,0)); 
      background-image:    -moz-linear-gradient(rgba(225,225,225,0), rgba(225,225,225,0.75), rgba(225,225,225,0)); 
      background-image:     -ms-linear-gradient(rgba(225,225,225,0), rgba(225,225,225,0.75), rgba(225,225,225,0)); 
      background-image:      -o-linear-gradient(rgba(225,225,225,0), rgba(225,225,225,0.75), rgba(225,225,225,0));
  `};
`
