import React from 'react'
import { Location } from '@reach/router'

import {
  Container, CenterGrid, LeftGrid, RightGrid, BottomGrid, VerticalText
} from './styles'
import Footer from "../footer"

const FragmentWrapper = ({ leftContent, rightContent, children, bottomContent }) => {

  return (
    <Container>
      <LeftGrid>
        {leftContent()}
      </LeftGrid>
      <CenterGrid>
        {children}
      </CenterGrid>
      <RightGrid>
        {rightContent()}
      </RightGrid>
      <BottomGrid>
        {bottomContent()}
      </BottomGrid>
    </Container>
  )
}

FragmentWrapper.defaultProps = {
  leftContent: () => (
    <Location>
    {props => <VerticalText>{props.location.pathname.slice(1).toUpperCase()}</VerticalText>}
  </Location>),
  bottomContent: () => (<Footer />)
}

export default FragmentWrapper
