import PropTypes from "prop-types"
import React from "react"
import { Box, Column } from "gestalt"
import { Box as GBox } from 'grommet'

import { Wrapper, CopyRight, HortLine, BackText, LinkB } from "./styles"
import SocialBar from "../social-bar"

const Footer = ({ darkMode }) => {

  return (
    <Wrapper>
      <Box
        alignItems="center"
        direction="row"
        display="flex"
        color="transparent"
        wrap
        justifyContent="between"
      >
        <Column span={12} mdSpan={6}>
          <LinkB onClick={() => window.history.go(-1)}>
            <GBox direction="row" flex>
              <HortLine darkMode={darkMode} />
              <BackText darkMode={darkMode}>GO BACK</BackText>
            </GBox>
          </LinkB>
        </Column>
        <Column span={12} mdSpan={6}>
          <Box
            alignSelf="center"
            justifyContent="center"
            smDisplay="block"
            lgDisplay="none"
            mdDisplay="none"
            alignItems="center"
          >
            <br />
            <br />
            <SocialBar darkMode={darkMode} hort />
          </Box>
          <Box padding={4}>
            <CopyRight darkMode={darkMode}>COPYRIGHT &#9400; {new Date().getFullYear()} REX ISAAC RAPHAEL</CopyRight>
          </Box>
        </Column>
      </Box>
    </Wrapper>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
