import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFragment from "../components/fragments/contact-fragment";

const ContactPage = (props) => (
  <Layout {...props}>
    <SEO title="Contact" />
    <ContactFragment />
  </Layout>
)

export default ContactPage
